/**
 * Simple component to check whether a given page exists or not.
 */
export default {
  /**
   * Check the given url via HEAD request.
   * @param url   The url of the page you want to check
   * @returns {Promise<unknown>}
   */
  exists: (url) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'HEAD',
      })
        .then((response) => {
          //
          if (response.status === 200 || response.status === 301) resolve(true);
          //
          // if (!response.ok) reject(response.statusText);
          //
          // if (response.status === 200 || response.status === 301) resolve(true);
          //
          resolve(false);
        })
        .catch((err) => reject(err));
    });
  },
};
