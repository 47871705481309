import cookie from './Cookie';
import GeoDetection from '../../../../core/js/libs/GeoDetection';
import UrlParser from './UrlParser';
import urlChecker from './UrlChecker';
import PopUp from './PopUp';
import UrlTransform from '../../../../core/js/libs/UrlTransform';
import Logger from './Logger';

/**
 * Class EducatedGuess are the main handler for detection whether
 * an user are visiting the 'wrong' website for his language and
 * country.
 */
export default class EducatedGuess {
  /**
   *
   * @param url   The url we should work with
   */
  constructor(url) {
    //
    this.currUrl = url;
  }

  /**
   * Gets the values the cookie of a previous call to the geo-coding service
   * @returns boolean
   */
  getCookie() {
    return cookie.getCookie(EducatedGuess.COOKIE_NAME) === 'true';
  }

  /**
   * Sets the cookie for the geo-detection
   */
  setCookie() {
    //
    cookie.setCookie(EducatedGuess.COOKIE_NAME, JSON.stringify(true), EducatedGuess.COOKIE_EXPIRY_DAYS);
  }

  /**
   * Run the geo-detection.
   */
  run() {
    Logger.log('EducatedGuess => run()');
    // parse current url to get the lang and country information
    const parser = new UrlParser(this.currUrl);
    const parseResult = parser.parse();
    //
    if (parseResult === false) {
      console.error("Couldn't determine lang and country from current url");
      return;
    }

    let transform;
    let localizedUrl;
    const hasCookie = this.getCookie();

    Logger.log(`EducatedGuess => has cookie: "${hasCookie}"`);
    Logger.log(`EducatedGuess => lang/country from url: "${parser.language}", "${parser.country}"`);

    // check if we already have saved an cookie. If so
    // abort as the user already opted-in via cookie.
    if (hasCookie) return;

    // call the service to get the users language and country
    // based on his/her ip address.
    GeoDetection.create(window.CONF.GEODETECTION_SERVICE)
      .inject()
      .then(({ country, language }) => {
        Logger.log(`EducatedGuess => lang/country from geo-detection: "${language}", "${country}"`);

        // if lang and country mismatch, show popup to ask if we
        // should visit correct website for the users language and country
        if (parser.country !== country) {
          // transform the url to the new lang/country pair
          transform = new UrlTransform(this.currUrl);
          localizedUrl = transform.transformTo(language, country);

          Logger.log('| - user not on correct page! Correct url would be -> ', localizedUrl);
          Logger.log('Checking if localized-url exists...');

          // check if localized version of the current page exists
          this.getUrlExists(localizedUrl)
            .then((exists) => {
              // if localized url does not exist ...
              if (!exists) {
                // ... redirect user to language selection page
                localizedUrl = transform.transformToHome();
              }

              Logger.log(`   |- ${exists ? 'exists!' : 'exists NOT!'} rendering pop-up...`);

              // otherwise render popup
              this._render(localizedUrl, exists, language, country, parser.language, parser.country);
            })
            .catch((err) => console.error(err));
        } else {
          // set values from geo-localization service, so
          // future request won't be made
          Logger.info('EducatedGuess => already on correct page');
          // set cookie with values from geo-service
          // this.setCookie({ lang: language, country: country });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Check if given url exists.
   * @param url
   * @returns {Promise}
   */
  getUrlExists(url) {
    return urlChecker.exists(url);
  }

  /**
   * Redirect browser to given url
   * @param url
   */
  redirectTo(url) {
    // ensure we have a trailing slash
    if (url.lastIndexOf('/') !== url.length - 1) {
      url += '/';
    }
    // redirect...
    window.location.href = url;
  }

  /**
   * Show the pop-up.
   * @param url           The url the user will redirected after confirming to change lang/country. This
   *                      could either be the current page in his lang/country, or the homepage of his
   *                      lang/country
   * @param urlExists     Whether the url of the current page exists in the lang/country of the user.
   * @param destLang      The lang identifier (de, en, ..) which should be set to cookie after button click.
   * @param destCountry   The country identifier (de, gb, ..) which should be set to cookie after button click.
   * @param sourceLang    The language from the url eg. the current visited page
   * @param sourceCountry The country from the url eg. the current visited page
   * @private
   */
  _render(url, urlExists, destLang, destCountry, sourceLang, sourceCountry) {
    Logger.log('EducatedGuess.js => _render()');
    Logger.log(` |- urlExists: ${urlExists}`);
    Logger.log(` |- dest: ${destLang} | ${destCountry}`);
    Logger.log(` |- source: ${sourceLang} | ${sourceCountry}`);

    //
    const popUp = new PopUp(destLang, destCountry, sourceLang, sourceCountry);

    //
    popUp.setChangeHandler(() => {
      // set cookie
      this.setCookie();

      // redirect to the given url
      this.redirectTo(url);
    });

    //
    popUp.setCloseHandler(() => {
      // set cookie to save user decision
      this.setCookie({ lang: sourceLang, country: sourceCountry });

      // close popup
      popUp.hide();
    });

    //
    popUp.show(urlExists);
  }
}

// the name of the cookie we will set to determine whether the user
// has already confirmed changing to his/her localized version of the website.
EducatedGuess.COOKIE_NAME = 'WirtgenEducatedGuess';
// the number of days until our cookie will expire
EducatedGuess.COOKIE_EXPIRY_DAYS = 7;
// the number of milliseconds, our popup will open after the cookie-consent
// manager has either been confirmed or declined
EducatedGuess.POP_UP_DELAY = 3000;
