import Cookie from './Cookie';

/**
 *
 */
export default class Logger {
  /**
   *
   * @param msg
   */
  static log(msg) {
    // only log if test mode is active
    if (!Cookie.getCookie('WirtgenEducatedGuessTestMode')) return;

    console.log(msg);
  }

  /**
   *
   * @param msg
   */
  static info(msg) {
    // only log if test mode is active
    if (!Cookie.getCookie('WirtgenEducatedGuessTestMode')) return;

    console.info(msg);
  }
}
