/**
 * Static imports always required! ###
 */
import '../../components/a/icon';
import '../../components/a/picture';
import '../../components/m/geodetection';

const isThere = (selector) => document.querySelector(selector) !== null;

/**
 * Dynamic imports splited in chunks
 */
if (isThere('.select')) {
  import(/* webpackChunkName: "select" */ '../../components/a/select');
}

if (isThere('.back-top')) {
  import(/* webpackChunkName: "back-top" */ '../../components/a/back-top');
}

if (isThere('.teaser-slider')) {
  import(/* webpackChunkName: "teaser-slider" */ '../../components/m/teaser-slider');
}

if (isThere('.bullets__container')) {
  import(/* webpackChunkName: "bullets" */ '../../components/m/bullets');
}

if (isThere('#header:not(.header--slim)')) {
  import(/* webpackChunkName: "header" */ '../../components/o/header');
}

if (isThere('.navbar-cart')) {
  import(/* webpackChunkName: "navbar-cart" */ '../../components/o/header/nav-item/cart');
}

if (isThere('.tabs')) {
  import(/* webpackChunkName: "tabs" */ '../../components/m/tabs');
}

if (isThere('.tabs-small-top')) {
  import(/* webpackChunkName: "tabs" */ '../../components/m/tabs-small-top');
}

if (isThere('.video-thumbnail')) {
  import(/* webpackChunkName: "video-thumbnail" */ '../../components/m/video/video-thumbnail');
}

if (isThere('.modal--slider')) {
  import(/* webpackChunkName: "modal--slider" */ '../../components/m/modal/modal--slider');
}

if (isThere('.image-set')) {
  import(/* webpackChunkName: "image-set" */ '../../components/m/image-set');
}

if (isThere('.productfilter')) {
  import(/* webpackChunkName: "productfilter" */ '../../components/m/productfilter');
}

if (isThere('.stage-slider')) {
  import(/* webpackChunkName: "stage-slider" */ '../../components/m/stage-slider');
}

if (isThere('.dealer')) {
  import(/* webpackChunkName: "dealer" */ '../../components/o/dealer');
}

if (isThere('.anchor-nav')) {
  import(/* webpackChunkName: "anchor-nav" */ '../../components/m/anchor-navigation');
}

if (isThere('.tab-navigation')) {
  import(/* webpackChunkName: "tab-navigation" */ '../../components/m/tab-navigation');
}

if (isThere('.hotspots')) {
  import(/* webpackChunkName: "hotspots" */ '../../components/m/hotspots');
}

if (isThere('.table-top')) {
  import(/* webpackChunkName: "table-top" */ '../../components/m/table-top');
}

if (isThere('.table-left')) {
  import(/* webpackChunkName: "table-left" */ '../../components/m/table-left');
}

if (isThere('.product-stage')) {
  import(/* webpackChunkName: "product-stage" */ '../../components/m/product-stage');
}

if (isThere('.teaser-gallery')) {
  import(/* webpackChunkName: "teaser-gallery" */ '../../components/m/teaser-gallery');
}

if (isThere('.brand-selector')) {
  import(/* webpackChunkName: "brand-selector" */ '../../components/m/brand-selector');
}

if (isThere('.iframe')) {
  import(/* webpackChunkName: "iframe" */ '../../components/m/iframe');
}

if (isThere('.newsletter')) {
  import(/* webpackChunkName: "newsletter" */ '../../components/o/newsletter');
}

if (isThere('.scrolltable')) {
  import(/* webpackChunkName: "table" */ '../../components/m/table');
}

if (isThere('.accordion')) {
  import(/* webpackChunkName: "accordion" */ '../../components/m/accordion');
}

if (isThere('.btabs')) {
  import(/* webpackChunkName: "btabs" */ '../../components/m/btabs');
}

if (isThere('.simple-teaser')) {
  import(/* webpackChunkName: "simple-teaser" */ '../../components/m/simple-teaser');
}

if (isThere('.changeOrder')) {
  import(/* webpackChunkName: "changeOrder" */ '../../components/m/modal/changeOrder');
}

if (isThere('.product-comparison') || isThere('.products-tile-view') || isThere('.table-left')) {
  import(/* webpackChunkName: "product-comparison" */ '../../components/o/product-comparison');
}

if (isThere('.reference-object-filter')) {
  import(/* webpackChunkName: "reference-object-filter" */ '../../components/m/reference-object-filter');
}

if (isThere('.pam-filter')) {
  import(/* webpackChunkName: "pam-filters" */ '../../components/o/pam-filters');
}

if (isThere('.stage-search')) {
  import(/* webpackChunkName: "stage-search" */ '../../components/m/stage-search');
}

if (isThere('.give-me-five')) {
  import(/* webpackChunkName: "give-me-five" */ '../../components/m/give-me-five');
}

if (isThere('.notepad')) {
  import(/* webpackChunkName: "notepad" */ '../../components/m/notepad');
}

if (isThere('.btn--trigger-cart') || isThere('.filter-result-chisels')) {
  import(/* webpackChunkName: "add-to-cart" */ '../../components/m/add-to-cart-button');
}

if (isThere('.clamped-text')) {
  import(/* webpackChunkName: "clamped-text" */ '../../components/m/clamped-text');
}

if (isThere('.image-slider-text')) {
  import(/* webpackChunkName: "image-slider-text" */ '../../components/m/image-slider-text');
}

if (isThere('.background-image-text')) {
  import(/* webpackChunkName: "background-image-text" */ '../../components/m/background-image-text');
}

if (isThere('.image-accordion-vertical')) {
  import(/* webpackChunkName: "clamped-text" */ '../../components/m/image-accordion-vertical');
}

if (isThere('.sticky-nav')) {
  import(/* webpackChunkName: "sticky-nav" */ '../../components/m/sticky-navigation');
}

if (isThere('.stage-slider-mag')) {
  import(/* webpackChunkName: "stage-slider-mag" */ '../../components/m/stage-slider-mag');
}

if (isThere('.background-image-hotspots')) {
  import(/* webpackChunkName: "background-image-hotspots" */ '../../components/m/background-image-hotspots');
}

if (isThere('.linked-image-tiles')) {
  import(/* webpackChunkName: "linked-image-tiles" */ '../../components/m/linked-image-tiles');
}

if (isThere('.search-slot')) {
  import(/* webpackChunkName: "search-slot" */ '../../components/m/search-slot');
}

if (isThere('.search-result')) {
  import(/* webpackChunkName: "search-result" */ '../../components/o/search-result');
}

if (isThere('.reference-object-snippet-variants')) {
  import(
    /* webpackChunkName: "reference-object-snippet-variants" */ '../../components/m/reference-object-snippet-variants'
  );
}

if (isThere('.component-selector')) {
  import(/* webpackChunkName: "component-selector" */ '../../components/m/component-selector');
}

if (isThere('.reference-object-snippet')) {
  import(/* webpackChunkName: "reference-object-snippet" */ '../../components/m/reference-object-snippet');
}

if (isThere('.jobfind')) {
  import(/* webpackChunkName: "jobfind" */ '../../components/o/jobfind');
}

if (isThere('.hotspots-xray')) {
  import(/* webpackChunkName: "hotspots-xray" */ '../../components/m/hotspots-xray');
}

if (isThere('.contentfilter')) {
  import(/* webpackChunkName: "contentfilter" */ '../../components/m/contentfilter');
}

if (isThere('body[data-error-id][data-error-type]')) {
  import(/* webpackChunkName: "error-track" */ '../../components/m/error-track');
}

if (isThere('.country-list')) {
  import(/* webpackChunkName: "country-list" */ '../../components/m/country-list');
}

if (isThere('.services-tiles')) {
  import(/* webpackChunkName: "services-tiles" */ '../../components/m/services-tiles');
}

if (isThere('.services-tiles-scroll')) {
  import(/* webpackChunkName: "services-tiles-scroll" */ '../../components/m/services-tiles-scroll');
}

if (isThere('.masonry-teaser')) {
  import(/* webpackChunkName: "services-tiles" */ '../../components/m/masonry-teaser');
}

if (isThere('.anmelde-formular-roadclub')) {
  import(/* webpackChunkName: "anmelde-formular-roadclub" */ '../../components/o/anmelde-formular-roadclub');
}

if (isThere('.chisel-filter')) {
  import(/* webpackChunkName: "chisel-filters" */ '../../components/o/chisel-filters');
}

if (isThere('.reference-object-snippet-chisels')) {
  import(
    /* webpackChunkName: "reference-object-snippet-chisels" */ '../../components/m/reference-object-snippet-chisels'
  );
}

if (isThere('.product-tile__col')) {
  import(/* webpackChunkName: "product-tile" */ '../../components/m/product-tile/');
}

if (isThere('.product-flags')) {
  import(/* webpackChunkName: "product-flags" */ '../../components/m/product-flags');
}
